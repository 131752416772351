
























































































































































































































































































































.AnnouncementPushJG {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
}
